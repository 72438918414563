
.react-confirm-alert-overlay {
    background: rgb(0 0 0 / 0.6) !important;
}

.react-confirm-alert-body h1 {
    font-size: 20px;
    border-bottom: 1px solid black;
    margin-bottom: 15px;
    padding-bottom: 10px;
}
.venue_type_select .css-13cymwt-control{
    border: none;
}