@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Nunito-Regular";
  src: url("../public/assets/fonts/nunito/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nunito-Medium";
  src: url("../public/assets/fonts/nunito/Nunito-Medium.ttf");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: url("../public/assets/fonts/nunito/Nunito-SemiBold.ttf");
}

@font-face {
  font-family: "Nunito-Bold";
  src: url("../public/assets/fonts/nunito/Nunito-Bold.ttf");
}

@font-face {
  font-family: "Nunito-Black";
  src: url("../public/assets/fonts/nunito/Nunito-Black.ttf");
}

.sticky {
  padding: 0 !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.sticky .text-center {
  display: none;
}
.sticky .text-center.font-Nunito_Regular {
  display: block;
}
.sticky .text-center.font-Nunito_Regular .float-left {
  padding: 10px 5px !important;
}
.sticky .text-center.font-Nunito_Regular img {
  width: 25px;
}
.sticky .text-center.font-Nunito_Regular h3 {
  font-size: 14px;
}

select {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")
    no-repeat right 1rem center;
  background-size: 1.1em;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
  outline-width: 0;
}

@media (min-width: 768px) {
  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }
}
@media (min-width: 1240px) {
  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }
}

.react-time-picker__wrapper {
  border: 0;
}
.react-time-picker__clear-button {
  display: none;
}
.fancybox__caption {
  color: #fff !important;
}

.blog_detail {
  float: left;
  width: 100%;
}
.blog_detail img {
  float: left;
  height: 250px;
  object-fit: cover;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 0;
  width: 90% !important;
}

.blog_detail table {
  border-bottom: solid 1px #e5e7eb;
  padding: 15px 0;
  margin: 10px 0;
  display: block;
}
.blog_detail table p {
  line-height: 1.75rem;
  font-size: 0.875rem;
  color: #000;
}
.blog_detail table h2 {
  line-height: 1.75rem;
  color: #000;
  font-size: 18px;
  font-family: Nunito-SemiBold, sans-serif;
  position: relative;
  padding: 0 0 30px 0;
}
.blog_detail table h2:after {
  width: 70px;
  height: 2px;
  background: rgb(123 0 63);
  content: "";
  float: left;
  position: absolute;
  bottom: 20px;
  left: 0;
}

@media (min-width: 1024px) {
  .blog_detail table p {
    font-size: 1rem;
  }
  .blog_detail table h2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
}

@media screen and (max-width: 767px) {
  .blog_detail table {
  }
  .blog_detail img {
    width: 100% !important;
  }
  .blog_detail table caption {
    font-size: 1.3em;
  }
  .blog_detail table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .blog_detail table tr {
    display: block;
    margin-bottom: 0.625em;
    float: left;
  }
  .blog_detail table td {
    float: left;
    text-align: left;
  }
  .blog_detail table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .blog_detail table td:last-child {
    border-bottom: 0;
    width: 100% !important;
  }
}

.top_bar_select .css-13cymwt-control {
  background-color: rgb(243 244 246);
  border: 0;
}
.top_bar_select .css-1u9des2-indicatorSeparator {
  display: none;
}
.top_bar_select .css-1jqq78o-placeholder {
  color: #000;
}
.top_bar_select .css-tj5bde-Svg {
  fill: #000;
}

.cms_pages {
}
.cms_pages p {
  font-size: 1rem;
  color: #2d2d2d;
  font-family: "Nunito-Regular";
  padding-bottom: 1.5rem;
  line-height: 1.75rem;
}
.cms_pages ul {
  display: block;
}
.cms_pages ul li {
  display: block;
  font-size: 1rem;
  color: #2d2d2d;
  font-family: "Nunito-Regular";
  line-height: 1.75rem;
  padding: 3px 0 3px 30px;
  position: relative;
}
.cms_pages ul li:before {
  content: "\f4cb";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 18px;
}

.navbar {
  position: relative;
}
.navbar li.submenu ul {
  display: none;
  position: absolute;
  left: -15px;
  top: 100%;
  width: 200px;
  background-color: #fff;
}
.navbar li.submenu ul li {
  display: block;
  padding: 0 15px;
}
.navbar .toggle_btn {
  display: none;
}

@media screen and (min-width: 768px) {
  .navbar li.submenu:hover ul {
    display: block;
  }
  .navbar li.submenu {
    padding-right: 20px;
  }
  .navbar li.submenu:after {
    content: "\f078";
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";
    line-height: 40px;
    width: 14px;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 0;
    font-size: 14px;
  }
  .navbar li.submenu:hover:after {
    color: rgb(123 0 63);
  }
}

@media screen and (max-width: 768px) {
  .navbar .toggle_btn {
    display: block;
  }
  .navbar ul.hide_menu {
    display: none;
  }
  .navbar ul.show_menu {
    display: block;
    position: absolute;
    right: 0;
    top: 100%;
    width: 330px;
    padding: 15px;
  }
  .navbar li.submenu:after {
    content: "\2b";
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";
    line-height: 40px;
    width: 40px;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 0;
  }
  .navbar li.submenu ul.hide_submenu {
    display: none;
  }
  .navbar li.submenu ul.show_submenu {
    display: block;
    position: relative;
    left: 0;
  }
}

.start-time:before {
  content: "Guest Arrival Time:";
  margin-right: 0.6em;
  color: #9d9d9d;
}

.end-time:before {
  content: "Guest Departure Time:";
  margin-right: 0.6em;
  color: #9d9d9d;
}

/* Type of Event style */

.event-select {
  padding: 8px;
}

/*mailing marging style */

.add-margin-right {
  margin-right: 21px;
}
.css-1u9des2-indicatorSeparator {
  background-color: white !important;
}

.css-tj5bde-Svg {
  color: #6b6868;
}

.css-1jqq78o-placeholder {
  color: hsl(0deg 1.98% 5.32%) !important;
}

/* // CSS Styles */
input[type="date"]::before {
  content: "Date of event :  ";
  margin-right: 5px;
  color: #999;
} /* Adjust color as needed */

/* doc-note-icon */

.doc-icon-note::before {
  content: "\f4cb";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 150px;
  top: 96px;
  font-size: 18px;
}
